import React, { useEffect, useRef, useState } from 'react';
import {
  ErrorMessage,
  Formik,
  FormikErrors,
  FormikProps,
  useFormikContext,
} from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormGroup,
  Checkbox,
  RadioGroup,
  Radio,
  Link,
} from '@mui/material';

import AcceptIcon from '@mui/icons-material/Check';
import RejectIcon from '@mui/icons-material/Clear';

import Button from '@mui/material/Button';

import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useApiClient, IApiError } from '../../hooks/apiClient';
import { IKycDto } from '../../models/kyc';
import { getActiveUser, getMainUser } from '../../redux/stateFuncs';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setToDoComplete } from '../../redux/slices/todo';
import { ToDoEnum } from '../../Enums';
import { DatePicker } from '@mui/x-date-pickers';
import { Troubleshoot } from '@mui/icons-material';


let initialValues: IKycDto = {
  id: '',
  contactId: '',
  contactLogicalName: '',
  ownerName: '',
  approverId: '',
  statuscode: '',
  firstName: '',
  surname: '',
  socialSecurityNumber: '',
  email: '',
  phoneNumber: '',
  streetAddress: '',
  zipCode: '',
  city: '',
  country: '',
  representative: false,
  representativeSocialSecurityNumber: '',
  relationshipToCustomer: '',
  representativeId: '',
  representativeName: '',
  beneficialOwnerPep: false,
  businessrelationshipForWhom: '',
  businessrelationshipForWhomSocialSecurityNumber: '',
  businessrelationshipForWhomId: '',
  realMandatorPep: false,
  realMandatorPepName: '',
  realMandatorPepSocialSecurityNumber: '',
  taxDomicileOrAffiliation: false,
  isLivingInAnotherCountry: false,
  citizenshipInAnotherCountry: false,
  connectionToUsa: false,
  taxResidencyOtherThanSweden: false,
  selfDeclarationCountry: '',
  selfDeclarationTinOrEquivalent: '',
  taxableActivity: '',
  isPep: false,
  functionAsPep: '',
  pepFunctionCeased: null,
  familyMemberPep: false,
  familyMemberPepName: '',
  familyMemberPepSocialSecurityNumber: '',
  connectionToCustomer: '',
  familyMemberFunctionAsPep: '',
  familyMemberFunctionCeased: null,
  depositsFromSvenskBankOrAnother: false,
  depositsFromForeignBankOrOtherWithinEss: false,
  depositsFromForeignBankOrOtherOutsideEss: false,
  mainReasonForInvestmentCapitalInvestment: false,
  mainReasonForInvestmentPension: false,
  averageExpectedDeposit: 0,
  depositFrequency: 0,
  rejectComment: '',

  moneyOriginSalary: false,
  moneyOriginPension: false,
  moneyOriginSavings: false,
  moneyOriginReturnOnInvestments: false,
  moneyOriginInsurance: false,
  moneyOriginRealEstateSales: false,
  moneyOriginCompanySales: false,
  moneyOriginInherited: false,
  moneyOriginOther: false,
  moneyOriginOtherSpecified: '',
};

function isSSNValid(ssn: string | null): boolean {
  if (!ssn) return true;

  if (ssn.length !== 12) {
    return false;
  }
  ssn = ssn.replace(/\D/g, '');
  if (ssn.length !== 12) {
    return false;
  }

  ssn = ssn.substring(2, 12);

  let ssnArray = ssn.split('').reverse();

  if (ssnArray.length !== 10) {
    return false;
  }

  var sum = ssnArray
    .map((n) => {
      return Number(n);
    })
    .reduce((previous, current, index) => {
      // multiply every other number with two
      if (index % 2) current *= 2;
      // if larger than 10 get sum of individual digits (also n-9)
      if (current > 9) current -= 9;

      return previous + current;
    });

  // sum must be divisible by 10
  return 0 === sum % 10;
}

const KYCSchema = Yup.object()
  .shape(
    {
      id: Yup.string().required('Required'),
      contactId: Yup.string().required('Required'),
      contactLogicalName: Yup.string().required('Required'),
      ownerName: Yup.string().required('Required'),
      approverId: Yup.string().nullable(),
      statuscode: Yup.string(),
      firstName: Yup.string().required('Required'),
      surname: Yup.string().required('Required'),
      socialSecurityNumber: Yup.string().required('Required'),
      email: Yup.string().email().required('Required'),
      phoneNumber: Yup.string()
        //.matches(/^[\+]?[0-9]{2,4}?[-\s\.]?[0-9\s]{5,9}$/, 'Invalid phone number')
        .required('Required'),
      streetAddress: Yup.string().required('Required'),
      zipCode: Yup.string().required('Required'),
      city: Yup.string().required('Required'),
      country: Yup.string().required('Required'),
      representative: Yup.boolean().required(),
      representativeSocialSecurityNumber: Yup.string().nullable(),
      relationshipToCustomer: Yup.string().nullable(),
      representativeId: Yup.string().nullable(),
      representativeName: Yup.string().nullable(),
      beneficialOwnerPep: Yup.boolean().required(),
      businessrelationshipForWhom: Yup.string().when('beneficialOwnerPep', {
        is: true,
        then: (schema) => schema.required('Required'),
      }),
      businessrelationshipForWhomSocialSecurityNumber: Yup.string().when(
        'beneficialOwnerPep',
        {
          is: true,
          then: (schema) => schema.required('Required'),
        }
      ),
      realMandatorPep: Yup.boolean().required(),
      realMandatorPepName: Yup.string().when('realMandatorPep', {
        is: true,
        then: (schema) => schema.required('Required'),
      }),
      realMandatorPepSocialSecurityNumber: Yup.string().when(
        'realMandatorPep',
        {
          is: true,
          then: (schema) => schema.required('Required'),
        }
      ),
      taxDomicileOrAffiliation: Yup.boolean().required(),
      isLivingInAnotherCountry: Yup.boolean().when('taxDomicileOrAffiliation', {
        is: true,
        then: (schema) => schema.required('Required'),
      }),
      citizenshipInAnotherCountry: Yup.boolean().when(
        'taxDomicileOrAffiliation',
        {
          is: true,
          then: (schema) => schema.required('Required'),
        }
      ),
      connectionToUsa: Yup.boolean().when('taxDomicileOrAffiliation', {
        is: true,
        then: (schema) => schema.required('Required'),
      }),
      taxResidencyOtherThanSweden: Yup.boolean().when(
        'taxDomicileOrAffiliation',
        {
          is: true,
          then: (schema) => schema.required('Required'),
        }
      ),
      // selfDeclarationCountry: string; // TOOD: Get list of countries?
      selfDeclarationTinOrEquivalent: Yup.string().when(
        'taxDomicileOrAffiliation',
        {
          is: true,
          then: (schema) => schema.nullable(),
        }
      ),
      taxableActivity: Yup.string().when('taxDomicileOrAffiliation', {
        is: true,
        then: (schema) => schema.nullable(),
      }),
      isPep: Yup.boolean().required(),
      functionAsPep: Yup.string().when('isPep', {
        is: true,
        then: (schema) => schema.required('Required'),
      }),
      pepFunctionCeased: Yup.string().nullable(),
      familyMemberPep: Yup.boolean().required(),
      familyMemberPepName: Yup.string()
        .notRequired()
        .when('familyMemberPep', {
          is: true,
          then: (schema) => schema.required('Required'),
          otherwise: (schema) => schema.notRequired(),
        }),

      familyMemberPepSocialSecurityNumber: Yup.string().when(
        'familyMemberPep',
        {
          is: true,
          then: (schema) =>
            schema
              .required('Required')
              .test('familyPepSSN', 'Invalid SSN', function (val) {
                return isSSNValid(val);
              }),
          otherwise: (schema) => schema.notRequired(),
        }
      ),
      familyMemberFunctionAsPep: Yup.string().when('familyMemberPep', {
        is: true,
        then: (schema) => schema.required('Required'),
        otherwise: (schema) => schema.notRequired(),
      }),
      familyMemberFunctionCeased: Yup.string()
        .nullable()
        .when('familyMemberPep', {
          is: true,
          then: (schema) => schema.nullable().required('Required'),
          otherwise: (schema) => schema.notRequired(),
        }),
      connectionToCustomer: Yup.string().when('familyMemberPep', {
        is: true,
        then: (schema) => schema.required('Required'),
        otherwise: (schema) => schema.notRequired(),
      }),
      depositsFromSvenskBankOrAnother: Yup.boolean().required(),
      depositsFromForeignBankOrOtherWithinEss: Yup.boolean().required(),
      depositsFromForeignBankOrOtherOutsideEss: Yup.boolean().required(),
      mainReasonForInvestmentCapitalInvestment: Yup.boolean().required(),
      mainReasonForInvestmentPension: Yup.boolean().required(),
      averageExpectedDeposit: Yup.number().min(1, 'Required').required('Required'),
      depositFrequency: Yup.number().min(1, 'Required').required('Required'),
      moneyOriginSalary: Yup.boolean().required(),
      moneyOriginPension: Yup.boolean().required(),
      moneyOriginSavings: Yup.boolean().required(),
      moneyOriginReturnOnInvestments: Yup.boolean().required(),
      moneyOriginInsurance: Yup.boolean().required(),
      moneyOriginRealEstateSales: Yup.boolean().required(),
      moneyOriginCompanySales: Yup.boolean().required(),
      moneyOriginInherited: Yup.boolean().required(),
      moneyOriginOther: Yup.boolean().required(),
      moneyOriginOtherSpecified: Yup.string().when('moneyOriginOther', {
        is: true,
        then: (schema) => schema.required('Required'),
        otherwise: (schema) => schema.notRequired(),
      }),
    },
    [
      ['familyMemberPep', 'familyMemberPepName'],
      ['familyMemberPep', 'familyMemberPepSocialSecurityNumber'],
      ['familyMemberPep', 'connectionToCustomer'],
      ['familyMemberPep', 'familyMemberFunctionAsPep'],
      ['familyMemberPep', 'familyMemberFunctionCeased'],
    ]
  )
  .test('mainReasonForInvestmentTest', 'Required', (obj) => {
    if (
      obj.mainReasonForInvestmentCapitalInvestment === true ||
      obj.mainReasonForInvestmentPension === true
    ) {
      return true; // everything is fine
    }

    return new Yup.ValidationError(
      'Required',
      null,
      'mainReasonForInvestmentPension'
    );
  })
  .test('moneyOrginTest', 'Required', (obj) => {
    if (
      obj.moneyOriginSalary === true ||
      obj.moneyOriginPension === true ||
      obj.moneyOriginSavings === true ||
      obj.moneyOriginReturnOnInvestments === true ||
      obj.moneyOriginInsurance === true ||
      obj.moneyOriginRealEstateSales === true ||
      obj.moneyOriginCompanySales === true ||
      obj.moneyOriginInherited === true ||
      obj.moneyOriginOther === true
    ) {
      return true; // everything is fine
    }

    return new Yup.ValidationError('Required', null, 'moneyOriginOther');
  })
  .test('depositChoicesTest', 'Required', (obj) => {
    if (
      obj.depositsFromSvenskBankOrAnother === true ||
      obj.depositsFromForeignBankOrOtherWithinEss === true ||
      obj.depositsFromForeignBankOrOtherOutsideEss === true
    ) {
      return true; // everything is fine
    }

    return new Yup.ValidationError(
      'Required',
      null,
      'depositsFromForeignBankOrOtherOutsideEss'
    );
  })
  .test('taxDomicileOrAffiliationUSTest', 'Required', (obj) => {
    if (
      obj.taxDomicileOrAffiliation === false || // Only check rest if this is true
      obj.isLivingInAnotherCountry === true ||
      obj.connectionToUsa === true ||
      obj.citizenshipInAnotherCountry === true ||
      obj.taxResidencyOtherThanSweden === true
    ) {
      return true; // everything is fine
    }

    return new Yup.ValidationError(
      'Required',
      null,
      'taxResidencyOtherThanSweden'
    );
  });

function KycForm(props: any) {
  const api = useApiClient();
  const users = useAppSelector((state) => state.users);
  const dispatch = useAppDispatch();

  const IsToDo = props.isToDo;
  const { t } = useTranslation();

  //confirm
  function FormDialogConfirm(props: any) {
    const [open, setOpen] = React.useState(false);
    const [apiError, setApiError] = React.useState<IApiError>();
    const { values, setSubmitting, isSubmitting, setFieldValue } =
      useFormikContext();

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = (isSubmit: boolean) => {
      if (isSubmit) {
        setSubmitting(true);
        const mainUserId = getMainUser(users)?.id;
        const kycData = values as IKycDto;
        const kycDataConfirmedStatuscode = {
          ...kycData,
          statuscode: 'Confirmed',
          approverId: mainUserId,
        };
        if (props.isEdit && !IsToDo) {
          api.post('api/kyc', { ...kycData }).then((res) => {
            const guid: string = res.data;
            kycDataConfirmedStatuscode.id = guid;
            api
              .post('api/kyc/update', { ...kycDataConfirmedStatuscode })
              .then((res) => {
                props.handleIsFetch(false);
                props.handlekycDataChanged(!props.dataChangd);
                props.handleIsEdit(false);
                setOpen(false);
              });
          });
        } else {
          api
            .post('api/kyc/update', { ...kycDataConfirmedStatuscode })
            .then((res) => {
              props.handleIsFetch(false);
              props.handlekycDataChanged(!props.dataChangd);
              if (IsToDo) {
                dispatch(setToDoComplete(ToDoEnum.Kyc));
              }
              setOpen(false);
            })
            .catch((error) => {
              setApiError(error);
              setSubmitting(false);
            });
        }
      } else {
        setOpen(false);
      }
    };

    return (
      <>
        {/* {props.isSubmitable ? (
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
            startIcon={<AcceptIcon />}
          >
            {t('Confirm')}
          </Button>
        ) : (
          <>
            <Button
              variant="contained"
              color="primary"
              disabled
              startIcon={<AcceptIcon />}
            >
              {t('Confirm')}
            </Button>
          </>
        )} */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
          startIcon={<AcceptIcon />}
          disabled={!props.isSubmitable}
        >
          {t('Confirm')}
        </Button>
        <Dialog
          maxWidth="sm"
          fullWidth={true}
          open={open}
          onClose={handleClose}
        >
          {isSubmitting ? (
            <>
              <DialogContent>
                <Box display="flex" justifyContent="center" my={6}>
                  <CircularProgress />
                </Box>
                <Box display="flex" justifyContent="center" my={6}>
                  <Typography> {t('LongLoading')}</Typography>
                </Box>
              </DialogContent>
            </>
          ) : (
            <>
              {apiError ? (
                <>
                  <DialogTitle sx={{ color: '#f00' }}>
                    {t('errors.ErrorKYC')}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {apiError.additionalDetails.message
                        ? apiError.additionalDetails.message
                        : apiError.title}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => handleClose(false)}>
                      {t('Close')}
                    </Button>
                  </DialogActions>
                </>
              ) : (
                <>
                  <DialogTitle>{t('LegalNote')}</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {t('kyc.dialog.ConfirmText')}
                      <br />
                      <Link
                        href="https://coeli.se/legal-information-coeli-wealth-management-ab/"
                        target={'_blank'}
                      >
                        https://coeli.se/legal-information-coeli-wealth-management-ab/
                      </Link>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => handleClose(true)}>
                      {t('Confirm')}
                    </Button>
                    <Button onClick={() => handleClose(false)}>
                      {t('Cancel')}
                    </Button>
                  </DialogActions>
                </>
              )}
            </>
          )}
        </Dialog>
      </>
    );
  }

  //reject
  function FormDialogReject(props: any) {
    const [open, setOpen] = React.useState(false);
    const [rejectComment, setRejectComment] = React.useState<string>('');
    const [apiError, setApiError] = React.useState<IApiError>();
    const { values, setSubmitting, isSubmitting } = useFormikContext();

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = (isSubmit: boolean) => {
      if (isSubmit) {
        setSubmitting(true);

        const mainUserId = getMainUser(users)?.id;
        const kycData = values as IKycDto;
        kycData.statuscode = 'Rejected';
        kycData.rejectComment = rejectComment;

        api
          .post('api/kyc/update', { ...kycData, approverId: mainUserId })
          .then((res) => {
            props.handlekycDataChanged(!props.dataChangd);
            if (IsToDo) {
              dispatch(setToDoComplete(ToDoEnum.Kyc));
            }
            setOpen(false);
          })
          .catch((error) => {
            setApiError(error);
            setSubmitting(false);
          });
      } else {
        setOpen(false);
      }
    };

    return (
      <>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
          startIcon={<RejectIcon />}
        >
          {t('Reject')}
        </Button>
        <Dialog
          maxWidth="sm"
          fullWidth={true}
          open={open}
          onClose={handleClose}
        >
          {isSubmitting ? (
            <>
              <DialogContent>
                <Box display="flex" justifyContent="center" my={6}>
                  <CircularProgress />
                </Box>
                <Box display="flex" justifyContent="center" my={6}>
                  <Typography> {t('LongLoading')}</Typography>
                </Box>
              </DialogContent>
            </>
          ) : (
            <>
              {apiError ? (
                <>
                  <DialogTitle sx={{ color: '#f00' }}>
                    {t('errors.ErrorKYC')}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {apiError.additionalDetails.message}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => handleClose(false)}>
                      {t('Close')}
                    </Button>
                  </DialogActions>
                </>
              ) : (
                <>
                  <DialogTitle>{t('LegalNote')}</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {t('kyc.dialog.RejectText')}
                    </DialogContentText>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="comment"
                      label={t('RejectReason')}
                      fullWidth
                      multiline
                      minRows={4}
                      variant="outlined"
                      required={true}
                      value={rejectComment}
                      onChange={(e) => setRejectComment(e.target.value)}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      disabled={rejectComment === '' ? true : false}
                      onClick={() => handleClose(true)}
                    >
                      {t('Reject')}
                    </Button>
                    <Button onClick={() => handleClose(false)}>
                      {t('Cancel')}
                    </Button>
                  </DialogActions>
                </>
              )}
            </>
          )}
        </Dialog>
      </>
    );
  }

  function BasicForm() {
    const [isEditable, setIsEditable] = useState(false);
    const [isEdit, setIsEdit] = useState(true);
    const [isKycEnabled, setIsKycEnabled] = useState(false);
    const [dataChanged, setDataChanged] = useState(false);
    const [isFetched, setIsFetched] = useState(false);
    const [isSubmitable, setIsSubmitable] = useState(false);
    const users = useAppSelector((state) => state.users);
    const activeUserCanSign = getActiveUser(users)?.canSign;

    const [isValid, setIsValid] = useState(false);

    const [componentValueState, setComponentValueState] =
      useState<ComponentValue | null>(null);

    let propertiesArray = Object.getOwnPropertyNames(initialValues);

    type kycType = { [key: string]: boolean };
    const initialTouched: kycType = {};
    propertiesArray.forEach((element) => {
      initialTouched[element] = true;
    });

    const handleEdit = (edit: boolean) => {
      setIsEditable(edit);
      setIsEdit(edit);
      setIsFetched(true);
    };

    const handleSubmitEdit = async (
      values: IKycDto,
      { resetForm, setErrors, setStatus, setSubmitting }: any
    ) => {};

    // Used to revalidate the hidden/shown fields from radio button group choices
    useEffect(() => {
      if (componentValueState != null) {
        formRef?.current?.setFieldValue(
          componentValueState.component,
          componentValueState.value
        );
      }
    }, [componentValueState]);

    useEffect(() => {
      setIsFetched(false);
      const activeUserSSN = getActiveUser(users)?.socialSecurityNumber;

      api.get(`api/kyc/${activeUserSSN}`).then((res) => {
        const kycs: IKycDto[] = res.data;

        let item = kycs.find(
          (kyc) => kyc.statuscode === 'ClientRevision'
        ) as IKycDto;
        if (item && IsToDo) {
          if (activeUserCanSign) {
            setIsEditable(true);
            setIsKycEnabled(true);
          } else {
            setIsKycEnabled(false);
          }
        } else {          
          item = kycs.find((kyc) => kyc.statuscode === 'Confirmed') as IKycDto;
          if (item) {
            // if (activeUserCanSign) {
            //   setIsKycEnabled(true);
            // }
            setIsKycEnabled(false);
          } else {
            setIsKycEnabled(false);
            initialValues = {} as IKycDto;
          }
        }
        if (item) {
          initialValues = { ...(item as IKycDto) };
        }
        setTimeout(() => {
          setIsFetched(true);
        }, 1000);
      });
    }, [users, dataChanged]);

    useEffect(() => {
      if (isFetched == true) {
        formRef.current?.validateForm().then((errors) => {

          console.log("Validated:");
          console.log(errors);
          console.log(formRef.current?.isValid);
        });
      }
    }, [isFetched]);

    const formRef = useRef<FormikProps<IKycDto>>(null);

    return (
      <>
        {isFetched ? (
          <Formik
            innerRef={formRef}
            validateOnMount={true}
            validationSchema={KYCSchema}
            initialValues={initialValues}
            initialErrors={{ firstName: 'Required' }}
            initialTouched={{ ...initialTouched }}
            onSubmit={handleSubmitEdit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              validateField,
              setSubmitting,
              handleReset,
              isSubmitting,
              touched,
              values,
              status,
              dirty,
              isValid
            }) =>
              values.id ? (
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {t('kyc.MyDataHeadline')}
                    </Typography>
                    {IsToDo ? (
                      <Typography variant="body2" gutterBottom>
                        {t('kyc.MyDataDesc')}
                      </Typography>
                    ) : (
                      <Typography variant="body2" gutterBottom>
                        {t('kyc.MyDataNonEditDesc')}
                      </Typography>
                    )}

                    <Typography display="inline">
                      Ansvarig hos Coeli:{' '}
                    </Typography>
                    <Typography
                      variant="body2"
                      display="inline"
                      fontWeight={'bold'}
                    >
                      {values.ownerName}
                    </Typography>
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} mt={4}>
                          {activeUserCanSign ? (
                            <>
                              {isKycEnabled &&
                              !isEditable &&
                              !IsToDo /* Activate when KYC should be editable when confirmed already*/ ? (
                                <>
                                  {/* Edit */}
                                  {/* <Button
                                    onClick={() => handleEdit(true)}
                                    variant="outlined"
                                    color="primary"
                                  >
                                    {t('Edit')}
                                  </Button> */}
                                </>
                              ) : isEditable && IsToDo ? (
                                <>
                                  {/* Confirm / Reject */}
                                  {isEdit ? (
                                    <Grid container spacing={1}>
                                      <Grid item>
                                        <FormDialogConfirm
                                          handleIsFetch={setIsFetched}
                                          handlekycDataChanged={setDataChanged}
                                          dataChangd={dataChanged}
                                          isEdit={isEdit}
                                          handleIsEdit={handleEdit}
                                          isSubmitable={
                                            isValid
                                          }
                                        ></FormDialogConfirm>
                                      </Grid>
                                      <Grid item>
                                        <FormDialogReject
                                          handleIsFetch={setIsFetched}
                                          handlekycDataChanged={setDataChanged}
                                          dataChangd={dataChanged}
                                        ></FormDialogReject>
                                      </Grid>
                                    </Grid>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : null}
                        </Grid>

                        <Grid item xs={12} mt={4}>
                          <TextField
                            name="firstName"
                            label={t('Firstname')}
                            value={values.firstName}
                            error={Boolean(
                              touched.firstName && errors.firstName
                            )}
                            fullWidth
                            helperText={touched.firstName && errors.firstName}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            disabled={!isEditable}
                            required
                          />
                        </Grid>

                        <Grid item xs={12} mt={4}>
                          <TextField
                            name="surname"
                            label={t('Lastname')}
                            value={values.surname}
                            error={Boolean(touched.surname && errors.surname)}
                            fullWidth
                            helperText={touched.surname && errors.surname}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            disabled={!isEditable}
                            required
                          />
                        </Grid>

                        <Grid item xs={12} mt={4}>
                          <TextField
                            name="socialSecurityNumber"
                            label={t('SocialSecurityNumber')}
                            value={values.socialSecurityNumber}
                            error={Boolean(
                              touched.socialSecurityNumber &&
                                !isSSNValid(values.socialSecurityNumber)
                            )}
                            fullWidth
                            helperText={
                              touched.socialSecurityNumber &&
                              errors.socialSecurityNumber
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            disabled={true}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          <TextField
                            name="email"
                            label={t('Email')}
                            value={values.email}
                            error={Boolean(touched.email && errors.email)}
                            fullWidth
                            helperText={touched.email && errors.email}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            disabled={!isEditable}
                          />
                        </Grid>

                        <Grid item xs={12} mt={4}>
                          <TextField
                            name="phoneNumber"
                            label={t('PhoneNumber')}
                            value={values.phoneNumber}
                            error={Boolean(
                              touched.phoneNumber && errors.phoneNumber
                            )}
                            fullWidth
                            helperText={
                              touched.phoneNumber && errors.phoneNumber
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            disabled={!isEditable}
                          />
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          <TextField
                            name="streetAddress"
                            label={t('StreetAddress')}
                            value={values.streetAddress}
                            error={Boolean(
                              touched.streetAddress && errors.streetAddress
                            )}
                            fullWidth
                            helperText={
                              touched.streetAddress && errors.streetAddress
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            disabled={!isEditable}
                          />
                        </Grid>

                        <Grid item xs={12} mt={4}>
                          <TextField
                            name="zipCode"
                            label={t('ZipCode')}
                            value={values.zipCode}
                            error={Boolean(touched.zipCode && errors.zipCode)}
                            fullWidth
                            helperText={touched.zipCode && errors.zipCode}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            disabled={!isEditable}
                          />
                        </Grid>

                        <Grid item xs={12} mt={4}>
                          <TextField
                            name="city"
                            label={t('City')}
                            value={values.city}
                            error={Boolean(touched.city && errors.city)}
                            fullWidth
                            helperText={touched.city && errors.city}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            disabled={!isEditable}
                          />
                        </Grid>

                        <Grid item xs={12} mt={4}>
                          <TextField
                            name="country"
                            label={t('Country')}
                            value={values.country}
                            error={Boolean(touched.country && errors.country)}
                            fullWidth
                            helperText={touched.country && errors.country}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            disabled={!isEditable}
                          />
                        </Grid>
                        {/* 8 */}
                        <Grid item xs={12} mt={4}>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">
                              {t('Represented')  + ' (' +  t('kyc.RepresentativeChangeInformation') + ')'}
                            </FormLabel>
                            <RadioGroup
                              aria-label={t('Represented')}
                              name="representative"
                              value={values.representative}
                            >
                              <FormControlLabel
                                value={'true'}
                                disabled={true}
                                control={<Radio />}
                                label={t('Yes')}
                                onChange={(e, val) => {
                                  setComponentValueState({
                                    component: 'representative',
                                    value: true,
                                  });
                                }}
                              />
                              <FormControlLabel
                                value={'false'}
                                disabled={!isEditable}
                                control={<Radio />}
                                label={t('No')}
                                onChange={(e, val) => {
                                  setComponentValueState({
                                    component: 'representative',
                                    value: false,
                                  });
                                }}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>

                        {values.representative === true ? (
                          <>
                            {/* 9 */}
                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="representativeName"
                                label={t('kyc.RepresentativeName')}
                                value={values.representativeName || ''}
                                error={Boolean(
                                  touched.representativeName &&
                                  errors.representativeName 
                                )}
                                fullWidth
                                helperText={
                                  touched.representativeName &&
                                  errors.representativeName
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={true}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <TextField
                              name="representativeSocialSecurityNumber"
                                label={t(
                                  'kyc.RepresentativeSocialSecurityNumber'
                                )}
                                value={
                                  values.representativeSocialSecurityNumber ||
                                  ''
                                }
                                error={Boolean(
                                  touched.representativeSocialSecurityNumber &&
                                    !isSSNValid(
                                      values.representativeSocialSecurityNumber
                                    )
                                )}
                                fullWidth
                                helperText={
                                  touched.representativeSocialSecurityNumber &&
                                  errors.representativeSocialSecurityNumber
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={true}
                              />
                            </Grid>

                            {/* 10 */}
                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="relationshipToCustomer"
                                label={t('kyc.RelationshipToCustomer')}
                                value={values.relationshipToCustomer || ''}
                                error={Boolean(
                                  touched.relationshipToCustomer &&
                                    errors.relationshipToCustomer
                                )}
                                fullWidth
                                helperText={
                                  touched.relationshipToCustomer &&
                                  errors.relationshipToCustomer
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={!isEditable}
                              />
                            </Grid>                            
                          </>
                        ) : null}

                        {/* 12 */}
                        <Grid item xs={12} mt={4}>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">
                              {t('kyc.BusinessRelationshipQuestion')}
                            </FormLabel>
                            <RadioGroup
                              aria-label="beneficialOwnerPep"
                              name="beneficialOwnerPep"
                              value={values.beneficialOwnerPep}
                            >
                              <FormControlLabel
                                value={'true'}
                                disabled={true}
                                control={<Radio />}
                                label={t('Yes')}
                                onChange={(e, val) => {
                                  setComponentValueState({
                                    component: 'beneficialOwnerPep',
                                    value: true,
                                  });
                                }}
                              />
                              <FormControlLabel
                                value={'false'}
                                disabled={!isEditable}
                                control={<Radio />}
                                label={t('No')}
                                onChange={(e, val) => {
                                  setComponentValueState({
                                    component: 'beneficialOwnerPep',
                                    value: false,
                                  });
                                }}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>

                        {values.beneficialOwnerPep == true ? (
                          <>
                            {/* 13 */}
                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="businessrelationshipForWhom"
                                label={t('kyc.BusinessrelationshipForWhom')}
                                value={values.businessrelationshipForWhom || ''}
                                error={Boolean(
                                  touched.businessrelationshipForWhom &&
                                    errors.businessrelationshipForWhom
                                )}
                                fullWidth
                                helperText={
                                  touched.businessrelationshipForWhom &&
                                  errors.businessrelationshipForWhom
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={true}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="BusinessrelationshipForWhomSocialSecurityNumber"
                                label={t(
                                  'kyc.BusinessrelationshipForWhomSocialSecurityNumber'
                                )}
                                value={
                                  values.businessrelationshipForWhomSocialSecurityNumber ||
                                  ''
                                }
                                error={Boolean(
                                  touched.businessrelationshipForWhomSocialSecurityNumber &&
                                    errors.businessrelationshipForWhomSocialSecurityNumber
                                )}
                                fullWidth
                                helperText={
                                  touched.businessrelationshipForWhomSocialSecurityNumber &&
                                  errors.businessrelationshipForWhomSocialSecurityNumber
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={true}
                              />
                            </Grid>

                            {/* 14 */}
                            <Grid item xs={12} mt={4}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  {t('kyc.RealMandatorPep')}
                                </FormLabel>
                                <RadioGroup
                                  aria-label="realMandatorPep"
                                  name="realMandatorPep"
                                  value={values.realMandatorPep}
                                >
                                  <FormControlLabel
                                    value={'true'}
                                    disabled={!isEditable}
                                    control={<Radio />}
                                    label={t('Yes')}
                                    onChange={(e, val) => {
                                      setComponentValueState({
                                        component: 'realMandatorPep',
                                        value: true,
                                      });
                                    }}
                                  />
                                  <FormControlLabel
                                    value={'false'}
                                    disabled={!isEditable}
                                    control={<Radio />}
                                    label={t('No')}
                                    onChange={(e, val) => {
                                      setComponentValueState({
                                        component: 'realMandatorPep',
                                        value: false,
                                      });
                                    }}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>

                            {values.realMandatorPep === true ? (
                              //{/* 15 */ }
                              <>
                                <Grid item xs={12} mt={4}>
                                  <TextField
                                    name="realMandatorPepName"
                                    label={t('kyc.RealMandatorPepName')}
                                    value={values.realMandatorPepName || ''}
                                    error={Boolean(
                                      touched.realMandatorPepName &&
                                        errors.realMandatorPepName
                                    )}
                                    fullWidth
                                    helperText={
                                      touched.realMandatorPepName &&
                                      errors.realMandatorPepName
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    variant="outlined"
                                    disabled={!isEditable}
                                  />
                                </Grid>

                                <Grid item xs={12} mt={4}>
                                  <TextField
                                    name="realMandatorPepSocialSecurityNumber"
                                    label={t(
                                      'kyc.RealMandatorPepSocialSecurityNumber'
                                    )}
                                    value={
                                      values.realMandatorPepSocialSecurityNumber ||
                                      ''
                                    }
                                    error={Boolean(
                                      touched.realMandatorPepSocialSecurityNumber &&
                                        !isSSNValid(
                                          values.realMandatorPepSocialSecurityNumber
                                        )
                                    )}
                                    fullWidth
                                    helperText={
                                      touched.realMandatorPepSocialSecurityNumber &&
                                      errors.realMandatorPepSocialSecurityNumber
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    variant="outlined"
                                    disabled={!isEditable}
                                  />
                                </Grid>
                              </>
                            ) : null}
                          </>
                        ) : null}

                        {/* 16 */}
                        <Grid item xs={12} mt={4}>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">
                              {t('kyc.TaxDomicileOrAffiliation')}
                            </FormLabel>
                            <RadioGroup
                              aria-label="taxDomicileOrAffiliation"
                              name="taxDomicileOrAffiliation"
                              value={values.taxDomicileOrAffiliation}
                            >
                              <FormControlLabel
                                value={'true'}
                                disabled={!isEditable}
                                control={<Radio />}
                                label={t('Yes')}
                                onChange={(e, val) => {
                                  setComponentValueState({
                                    component: 'taxDomicileOrAffiliation',
                                    value: true,
                                  });
                                }}
                              />
                              <FormControlLabel
                                value={'false'}
                                disabled={!isEditable}
                                control={<Radio />}
                                label={t('No')}
                                onChange={(e, val) => {
                                  setComponentValueState({
                                    component: 'taxDomicileOrAffiliation',
                                    value: false,
                                  });
                                }}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>

                        {values.taxDomicileOrAffiliation === true && (
                          <>
                            {/* 17 */}
                            <Grid item md={12} mt={2}>
                              <FormControl
                                sx={{ m: 3 }}
                                component="fieldset"
                                variant="standard"
                              >
                                <FormLabel component="legend">
                                  {t('kyc.TaxDomicileOrAffiliationUS')}
                                </FormLabel>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          values.isLivingInAnotherCountry
                                        }
                                        onChange={(e, val) => {
                                          setComponentValueState({
                                            component:
                                              'isLivingInAnotherCountry',
                                            value: val,
                                          });
                                        }}
                                        name="isLivingInAnotherCountry"
                                      />
                                    }
                                    disabled={!isEditable}
                                    label={t(
                                      'kyc.taxDomicileOrAffiliationChocies.ResidingAbroad'
                                    )}
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          values.citizenshipInAnotherCountry
                                        }
                                        onChange={(e, val) => {
                                          setComponentValueState({
                                            component:
                                              'citizenshipInAnotherCountry',
                                            value: val,
                                          });
                                        }}
                                        disabled={!isEditable}
                                        name="citizenshipInAnotherCountry"
                                      />
                                    }
                                    label={t(
                                      'kyc.taxDomicileOrAffiliationChocies.CitizenshipInAnotherCountry'
                                    )}
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.connectionToUsa}
                                        onChange={(e, val) => {
                                          setComponentValueState({
                                            component: 'connectionToUsa',
                                            value: val,
                                          });
                                        }}
                                        disabled={!isEditable}
                                        name="connectionToUsa"
                                      />
                                    }
                                    label={t(
                                      'kyc.taxDomicileOrAffiliationChocies.ConnectionToUSA'
                                    )}
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          values.taxResidencyOtherThanSweden
                                        }
                                        onChange={(e, val) => {
                                          setComponentValueState({
                                            component:
                                              'taxResidencyOtherThanSweden',
                                            value: val,
                                          });
                                        }}
                                        disabled={!isEditable}
                                        name="taxResidencyOtherThanSweden"
                                      />
                                    }
                                    label={t(
                                      'kyc.taxDomicileOrAffiliationChocies.TaxResidencyOtherThanSweden'
                                    )}
                                  />
                                </FormGroup>
                                <Box>
                                  <Typography className="errorMessage">
                                    <ErrorMessage name="taxResidencyOtherThanSweden" />
                                  </Typography>
                                </Box>
                              </FormControl>
                            </Grid>

                            {/* 18 */}
                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="selfDeclarationCountry"
                                label={t('kyc.SelfDeclarationCountry')}
                                value={values.selfDeclarationCountry}
                                error={Boolean(
                                  touched.selfDeclarationCountry &&
                                    errors.selfDeclarationCountry
                                )}
                                fullWidth
                                helperText={
                                  touched.selfDeclarationCountry &&
                                  errors.selfDeclarationCountry
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={!isEditable}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="selfDeclarationTinOrEquivalent"
                                label={t('kyc.SelfDeclarationTinOrEquivalent')}
                                value={values.selfDeclarationTinOrEquivalent}
                                error={Boolean(
                                  touched.selfDeclarationTinOrEquivalent &&
                                    errors.selfDeclarationTinOrEquivalent
                                )}
                                fullWidth
                                helperText={
                                  touched.selfDeclarationTinOrEquivalent &&
                                  errors.selfDeclarationTinOrEquivalent
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={!isEditable}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="taxableActivity"
                                label={t('kyc.TaxableActivity')}
                                value={values.taxableActivity}
                                error={Boolean(
                                  touched.taxableActivity &&
                                    errors.taxableActivity
                                )}
                                fullWidth
                                helperText={
                                  touched.taxableActivity &&
                                  errors.taxableActivity
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={!isEditable}
                              />
                            </Grid>
                          </>
                        )}
                        <Grid item xs={12} mt={4}>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">
                              {t('kyc.CustomerPEP')}
                            </FormLabel>
                            <RadioGroup
                              aria-label="isPep"
                              name="isPep"
                              value={values.isPep}
                            >
                              <FormControlLabel
                                value={'true'}
                                disabled={!isEditable}
                                control={<Radio />}
                                label={t('Yes')}
                                onChange={(e, val) => {
                                  setComponentValueState({
                                    component: 'isPep',
                                    value: true,
                                  });
                                }}
                              />
                              <FormControlLabel
                                value={'false'}
                                disabled={!isEditable}
                                control={<Radio />}
                                label={t('No')}
                                onChange={(e, val) => {
                                  setComponentValueState({
                                    component: 'isPep',
                                    value: false,
                                  });
                                }}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>

                        {values.isPep === true ? (
                          <>
                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="functionAsPep"
                                label={t('kyc.FunctionAsPep')}
                                value={values.functionAsPep}
                                error={Boolean(
                                  touched.functionAsPep && errors.functionAsPep
                                )}
                                fullWidth
                                helperText={
                                  touched.functionAsPep && errors.functionAsPep
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={!isEditable}
                              />
                            </Grid>

                            <Grid item xs={12} md={8} mt={4}>
                              <Typography>{t('FunctionCeased')}</Typography>
                              <DatePicker
                                format="dd-MM-yyyy"
                                value={
                                  values.pepFunctionCeased != null
                                    ? new Date(values.pepFunctionCeased)
                                    : null
                                }
                                onChange={(val) =>
                                  setFieldValue('pepFunctionCeased', val)
                                }
                                disabled={!isEditable}
                              />
                            </Grid>
                          </>
                        ) : null}

                        <Grid item xs={12} mt={4}>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">
                              {t('kyc.FamilyMemberPep')}
                            </FormLabel>
                            <RadioGroup
                              aria-label="familyMemberPep"
                              name="familyMemberPep"
                              value={values.familyMemberPep}
                            >
                              <FormControlLabel
                                value={'true'}
                                disabled={!isEditable}
                                control={<Radio />}
                                label={t('Yes')}
                                onChange={(e, val) => {
                                  setComponentValueState({
                                    component: 'familyMemberPep',
                                    value: true,
                                  });
                                }}
                              />
                              <FormControlLabel
                                value={'false'}
                                disabled={!isEditable}
                                control={<Radio />}
                                label={t('No')}
                                onChange={(e, val) => {
                                  setComponentValueState({
                                    component: 'familyMemberPep',
                                    value: false,
                                  });
                                }}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        {values.familyMemberPep === true && (
                          <>
                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="familyMemberPepName"
                                label={t('kyc.FamilyMemberPepName')}
                                value={values.familyMemberPepName}
                                error={Boolean(
                                  touched.familyMemberPepName &&
                                    errors.familyMemberPepName
                                )}
                                fullWidth
                                helperText={
                                  touched.familyMemberPepName &&
                                  errors.familyMemberPepName
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={!isEditable}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="familyMemberPepSocialSecurityNumber"
                                label={t(
                                  'kyc.FamilyMemberPepSocialSecurityNumber'
                                )}
                                value={
                                  values.familyMemberPepSocialSecurityNumber
                                }
                                error={Boolean(
                                  touched.familyMemberPepSocialSecurityNumber &&
                                    errors.familyMemberPepSocialSecurityNumber
                                )}
                                fullWidth
                                helperText={
                                  touched.familyMemberPepSocialSecurityNumber &&
                                  errors.familyMemberPepSocialSecurityNumber
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={!isEditable}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="familyMemberFunctionAsPep"
                                label={t('kyc.FunctionAsPep')}
                                value={values.familyMemberFunctionAsPep}
                                error={Boolean(
                                  touched.familyMemberFunctionAsPep &&
                                    errors.familyMemberFunctionAsPep
                                )}
                                fullWidth
                                helperText={
                                  touched.familyMemberFunctionAsPep &&
                                  errors.familyMemberFunctionAsPep
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={!isEditable}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="connectionToCustomer"
                                label={t('kyc.ConnectionToCustomer')}
                                value={values.connectionToCustomer}
                                error={Boolean(
                                  touched.connectionToCustomer &&
                                    errors.connectionToCustomer
                                )}
                                fullWidth
                                helperText={
                                  touched.connectionToCustomer &&
                                  errors.connectionToCustomer
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={!isEditable}
                              />
                            </Grid>
                            <Grid item xs={12} md={8} mt={4}>
                              <Typography>{t('FunctionCeased')}</Typography>
                              <DatePicker
                                format="dd-MM-yyyy"
                                value={
                                  values.familyMemberFunctionCeased != null
                                    ? new Date(
                                        values.familyMemberFunctionCeased
                                      )
                                    : null
                                }
                                onChange={(val) =>
                                  setFieldValue(
                                    'familyMemberFunctionCeased',
                                    val
                                  )
                                }
                                disabled={!isEditable}
                              />
                            </Grid>
                          </>
                        )}

                        <Grid item xs={12} mt={4}>
                          <FormControl component="fieldset" variant="standard">
                            <FormLabel component="legend">
                              {t('kyc.MoneyOrigin')}
                            </FormLabel>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.moneyOriginSalary}
                                    onChange={(e, val) => {
                                      setComponentValueState({
                                        component: 'moneyOriginSalary',
                                        value: val,
                                      });
                                    }}
                                    disabled={!isEditable}
                                    name="moneyOriginSalary"
                                  />
                                }
                                label={t('kyc.moneyOrigin.Salary')}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.moneyOriginPension}
                                    onChange={(e, val) => {
                                      setComponentValueState({
                                        component: 'moneyOriginPension',
                                        value: val,
                                      });
                                    }}
                                    disabled={!isEditable}
                                    name="moneyOriginPension"
                                  />
                                }
                                label={t('kyc.moneyOrigin.Pension')}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.moneyOriginSavings}
                                    onChange={(e, val) => {
                                      setComponentValueState({
                                        component: 'moneyOriginSavings',
                                        value: val,
                                      });
                                    }}
                                    disabled={!isEditable}
                                    name="moneyOriginSavings"
                                  />
                                }
                                label={t('kyc.moneyOrigin.Savings')}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      values.moneyOriginReturnOnInvestments
                                    }
                                    onChange={(e, val) => {
                                      setComponentValueState({
                                        component:
                                          'moneyOriginReturnOnInvestments',
                                        value: val,
                                      });
                                    }}
                                    disabled={!isEditable}
                                    name="moneyOriginReturnOnInvestments"
                                  />
                                }
                                label={t('kyc.moneyOrigin.ReturnOfInvestments')}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.moneyOriginInsurance}
                                    onChange={(e, val) => {
                                      setComponentValueState({
                                        component: 'moneyOriginInsurance',
                                        value: val,
                                      });
                                    }}
                                    disabled={!isEditable}
                                    name="moneyOriginInsurance"
                                  />
                                }
                                label={t('kyc.moneyOrigin.Insurance')}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.moneyOriginRealEstateSales}
                                    onChange={(e, val) => {
                                      setComponentValueState({
                                        component: 'moneyOriginRealEstateSales',
                                        value: val,
                                      });
                                    }}
                                    disabled={!isEditable}
                                    name="moneyOriginRealEstateSales"
                                  />
                                }
                                label={t('kyc.moneyOrigin.RealEstateSales')}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.moneyOriginCompanySales}
                                    onChange={(e, val) => {
                                      setComponentValueState({
                                        component: 'moneyOriginCompanySales',
                                        value: val,
                                      });
                                    }}
                                    disabled={!isEditable}
                                    name="moneyOriginCompanySales"
                                  />
                                }
                                label={t('kyc.moneyOrigin.CompanySales')}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.moneyOriginInherited}
                                    onChange={(e, val) => {
                                      setComponentValueState({
                                        component: 'moneyOriginInherited',
                                        value: val,
                                      });
                                    }}
                                    disabled={!isEditable}
                                    name="moneyOriginInherited"
                                  />
                                }
                                label={t('kyc.moneyOrigin.Inherited')}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.moneyOriginOther}
                                    onChange={(e, val) => {
                                      setComponentValueState({
                                        component: 'moneyOriginOther',
                                        value: val,
                                      });
                                    }}
                                    disabled={!isEditable}
                                    name="moneyOriginOther"
                                  />
                                }
                                label={t('kyc.moneyOrigin.Other')}
                              />
                            </FormGroup>
                          </FormControl>
                          {values.moneyOriginOther === true && (
                            <>
                              <TextField
                                name="moneyOriginOtherSpecified"
                                label={t('kyc.PleaseSpecify')}
                                value={values.moneyOriginOtherSpecified || ''}
                                error={Boolean(
                                  touched.moneyOriginOtherSpecified &&
                                    errors.moneyOriginOtherSpecified
                                )}
                                fullWidth
                                helperText={
                                  touched.moneyOriginOtherSpecified &&
                                  errors.moneyOriginOtherSpecified
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={!isEditable}
                              />
                            </>
                          )}
                          <Box>
                            <Typography className="errorMessage">
                              <ErrorMessage name="moneyOriginOther" />
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={12} mt={4}>
                          <FormControl component="fieldset" variant="standard">
                            <FormLabel component="legend">
                              {t('kyc.DepositsFrom')}
                            </FormLabel>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      values.depositsFromSvenskBankOrAnother
                                    }
                                    onChange={(e, val) => {
                                      setComponentValueState({
                                        component:
                                          'depositsFromSvenskBankOrAnother',
                                        value: val,
                                      });
                                    }}
                                    disabled={!isEditable}
                                    name="depositsFromSvenskBankOrAnother"
                                  />
                                }
                                label={t('kyc.depositChoices.SwedishBank')}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      values.depositsFromForeignBankOrOtherWithinEss
                                    }
                                    onChange={(e, val) => {
                                      setComponentValueState({
                                        component:
                                          'depositsFromForeignBankOrOtherWithinEss',
                                        value: val,
                                      });
                                    }}
                                    disabled={!isEditable}
                                    name="depositsFromForeignBankOrOtherWithinEss"
                                  />
                                }
                                label={t('kyc.depositChoices.ForeignBankESS')}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      values.depositsFromForeignBankOrOtherOutsideEss
                                    }
                                    onChange={(e, val) => {
                                      setComponentValueState({
                                        component:
                                          'depositsFromForeignBankOrOtherOutsideEss',
                                        value: val,
                                      });
                                    }}
                                    disabled={!isEditable}
                                    name="depositsFromForeignBankOrOtherOutsideEss"
                                  />
                                }
                                label={t(
                                  'kyc.depositChoices.ForeignBankNonESS'
                                )}
                              />
                            </FormGroup>
                            <Box>
                              <Typography className="errorMessage">
                                <ErrorMessage name="depositsFromForeignBankOrOtherOutsideEss" />
                              </Typography>
                            </Box>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} mt={4}>
                          <FormControl component="fieldset" variant="standard">
                            <FormLabel component="legend">
                              {t('kyc.InvestReason')}
                            </FormLabel>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      values.mainReasonForInvestmentCapitalInvestment
                                    }
                                    onChange={(e, val) => {
                                      setComponentValueState({
                                        component:
                                          'mainReasonForInvestmentCapitalInvestment',
                                        value: val,
                                      });
                                    }}
                                    onBlur={handleBlur}
                                    disabled={!isEditable}
                                    name="mainReasonForInvestmentCapitalInvestment"
                                  />
                                }
                                label={t('kyc.investChoices.CapitalInvestment')}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      values.mainReasonForInvestmentPension
                                    }
                                    onChange={(e, val) => {
                                      setComponentValueState({
                                        component:
                                          'mainReasonForInvestmentPension',
                                        value: val,
                                      });
                                    }}
                                    onBlur={handleBlur}
                                    disabled={!isEditable}
                                    name="mainReasonForInvestmentPension"
                                  />
                                }
                                label={t('kyc.investChoices.Pension')}
                              />
                            </FormGroup>
                            <Box>
                              <Typography className="errorMessage">
                                <ErrorMessage name="mainReasonForInvestmentPension" />
                              </Typography>
                            </Box>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} mt={4}>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">
                              {t('kyc.AverageExpectedDeposits')}
                            </FormLabel>
                            <RadioGroup
                              aria-label="averageExpectedDeposit"
                              name="averageExpectedDeposit"
                              value={values.averageExpectedDeposit}
                              onChange={handleChange}
                            >
                              <FormControlLabel
                                value="1"
                                disabled={!isEditable}
                                control={<Radio />}
                                label={t(
                                  'kyc.averageExpectedDepositsChocies.Option1'
                                )}
                              />
                              <FormControlLabel
                                value="2"
                                disabled={!isEditable}
                                control={<Radio />}
                                label={t(
                                  'kyc.averageExpectedDepositsChocies.Option2'
                                )}
                              />
                              <FormControlLabel
                                value="3"
                                disabled={!isEditable}
                                control={<Radio />}
                                label={t(
                                  'kyc.averageExpectedDepositsChocies.Option3'
                                )}
                              />
                              <FormControlLabel
                                value="4"
                                disabled={!isEditable}
                                control={<Radio />}
                                label={t(
                                  'kyc.averageExpectedDepositsChocies.Option4'
                                )}
                              />
                            </RadioGroup>
                            <Box>
                              <Typography className="errorMessage">
                                <ErrorMessage name="averageExpectedDeposit" />
                              </Typography>
                            </Box>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} mt={4}>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">
                              {t('kyc.DepositFrequency')}
                            </FormLabel>
                            <RadioGroup
                              aria-label="depositFrequency"
                              name="depositFrequency"
                              value={values.depositFrequency}
                              onChange={handleChange}
                            >
                              <FormControlLabel
                                value="1"
                                disabled={!isEditable}
                                control={<Radio />}
                                label={t('kyc.depositFrequencyChocies.Option1')}
                              />
                              <FormControlLabel
                                value="2"
                                disabled={!isEditable}
                                control={<Radio />}
                                label={t('kyc.depositFrequencyChocies.Option2')}
                              />
                              <FormControlLabel
                                value="3"
                                disabled={!isEditable}
                                control={<Radio />}
                                label={t('kyc.depositFrequencyChocies.Option3')}
                              />
                            </RadioGroup>
                            <Box>
                              <Typography className="errorMessage">
                                <ErrorMessage name="depositFrequency" />
                              </Typography>
                            </Box>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          {activeUserCanSign ? (
                            <>
                              {isKycEnabled &&
                              !isEditable &&
                              !IsToDo /* Activate when KYC should be editable when confirmed already*/ ? (
                                <>
                                  {/* Edit */}
                                  {/* <Button
                                    onClick={() => handleEdit(true)}
                                    variant="outlined"
                                    color="primary"
                                  >
                                    {t('Edit')}
                                  </Button> */}
                                </>
                              ) : isEditable || IsToDo ? (
                                <>
                                  {/* Confirm / Reject */}
                                  {isEdit ? (
                                    <Grid container spacing={1}>
                                      <Grid item>
                                        <FormDialogConfirm
                                          handleIsFetch={setIsFetched}
                                          handlekycDataChanged={setDataChanged}
                                          dataChangd={dataChanged}
                                          isEdit={isEdit}
                                          handleIsEdit={handleEdit}
                                          isSubmitable={
                                            formRef.current?.isValid &&
                                            formRef.current?.dirty
                                          }
                                        ></FormDialogConfirm>
                                      </Grid>
                                      <Grid item>
                                        <FormDialogReject
                                          handleIsFetch={setIsFetched}
                                          handlekycDataChanged={setDataChanged}
                                          dataChangd={dataChanged}
                                        ></FormDialogReject>
                                      </Grid>
                                    </Grid>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : null}{' '}
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              ) : (
                <>
                  <Typography>{t('kyc.NoInfo')}</Typography>
                </>
              )
            }
          </Formik>
        ) : (
          <div>
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
            <Box display="flex" justifyContent="center" my={6}>
              <Typography> {t('LongLoading')}</Typography>
            </Box>
          </div>
        )}
      </>
    );
  }

  return (
    <React.Fragment>
      <BasicForm />
    </React.Fragment>
  );
}

export default KycForm;
